import React, { FC, PropsWithChildren } from 'react'
import { useAuth } from 'utils/authOkta'
import { isG1 } from 'utils/envUtils'
import { isClientPortal } from 'utils/isClientPortal'
import { OktaLoginError, ServiceError } from '../SystemError'
import { InternalAuthorization } from './components/InternalAuthorization'

const Secure: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated: isOktaAuthenticated, authorizationState } = useAuth()
  const isAuthed = authorizationState === 'authorized'

  if (!isAuthed || !isOktaAuthenticated) {
    if (isG1()) {
      switch (authorizationState) {
        case 'unauthorized':
          return <OktaLoginError showReachToSupport />
        case 'error':
          return <ServiceError />
        default:
          return null
      }
    } else if (!isClientPortal && !isAuthed) {
      return <InternalAuthorization state={authorizationState} />
    } else {
      return null
    }
  }

  return <>{children}</>
}

export default React.memo(Secure)
